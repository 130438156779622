import { initData } from '@/api/data'

export default {
  data() {
    return {
      loading: false,
      data: [],
      page: 0,
      size: 10,
      total: 0,
      url: '',
      params: {},
      query: {},
      time: 170
    }
  },
  methods: {
    async init() {
      if (!await this.beforeInit()) {
        return
      }
      return new Promise((resolve, reject) => {
        this.loading = true
        initData(this.url, this.params).then(res => {
          this.total = +res.totalElements;
          this.data = res.content;
          if(this.statistics){
            this.statistics = res.statistics;
          }
          if(this.treeData){
            if(this.params.isTree == 1){
              this.treeData = res.tree || [];
            }
          }
          setTimeout(() => {
            this.loading = false
          }, this.time)
          resolve(res)
        }).catch(err => {
          this.loading = false
          reject(err)
        })
      })
    },
    beforeInit() {
      return true
    },
    pageChange(e) {
      this.page = e - 1;
      this.init();
    },
    sizeChange(e) {
      this.page = 0
      this.size = e;
      this.init();
    },
    toQuery(query){
      this.page = 0;
      this.init();
    }
  }
}
