import request from '@/utils/request'

export function add(data) {
  return request({
    url: 'crm/jobs',
    method: 'post',
    data
  })
}

export function del(data) {
  return request({
    url: 'crm/jobs/delete',
    method: 'post',
    data,
  })
}

export function edit(data) {
  return request({
    url: 'crm/jobs',
    method: 'put',
    data
  })
}

export function updateIsPause(data) {
  return request({
    url: 'crm/jobs/switch',
    method: 'put',
    data,
  })
}

export function execution(data) {
  return request({
    url: 'crm/jobs/exec',
    method: 'post',
    data,
  })
}
